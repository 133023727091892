import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { InfosimiComponent } from './infosimi/infosimi.component';
import { MedicamentosComponent } from './medicamentos/medicamentos.component';
import { InicioComponent } from './inicio/inicio.component';
import { BusquedaComponent } from './busqueda/busqueda.component';
import { ProductoComponent } from './producto/producto.component';
// import { LocalizaComponent } from './localiza/localiza.component';
import { ContactanosComponent } from './contactanos/contactanos.component';
import { FranquiciaComponent } from './franquicia/franquicia.component';
import { PromocionesComponent } from './promociones/promociones.component';
import { HistoriaComponent } from './empresa/historia/historia.component';
import { ControlDeCalidadComponent } from './empresa/control-de-calidad/control-de-calidad.component';
import { BolsaDeTrabajoComponent } from './empresa/bolsa-de-trabajo/bolsa-de-trabajo.component';
import { SimitelComponent } from './empresa/simitel/simitel.component';
import { HigienePerfumeriaComponent } from './higiene-perfumeria/higiene-perfumeria.component';
import { SaludSexualComponent } from './salud-sexual/salud-sexual.component';
import { SuplementosComponent } from './suplementos/suplementos.component';
import { VariosComponent } from './varios/varios.component';
import { MaterialDeCuracionComponent } from './material-de-curacion/material-de-curacion.component';
import { ModeloCulturalComponent } from './empresa/modelo-cultural/modelo-cultural.component';
import { BuscandoComponent } from './buscando/buscando.component';
import { BasesLegalesComponent } from './bases-legales/bases-legales.component';

const pagesRoutes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      { path: '', component: InicioComponent, data: { titulo: 'Inicio' } },
      {
        path: 'historia',
        component: HistoriaComponent,
        data: { titulo: 'Historia' },
      },
      {
        path: 'modelo-cultural',
        component: ModeloCulturalComponent,
        data: { titulo: 'Modelo cultural' },
      },
      {
        path: 'control-de-calidad',
        component: ControlDeCalidadComponent,
        data: { titulo: 'Control de calidad' },
      },/*
      {
        path: 'bolsa-de-trabajo',
        component: BolsaDeTrabajoComponent,
        data: { titulo: 'Bolsa de Trabajo' },
      },
      {
        path: 'simitel',
        component: SimitelComponent,
        data: { titulo: 'Simitel' },
      },
      {
        path: 'infosimi/:id/:url',
        component: InfosimiComponent,
        data: { titulo: 'Infosimi' },
      },
      {
        path: 'medicamentos',
        component: MedicamentosComponent,
        data: { titulo: 'Medicamentos' },
      },
      {
        path: 'higiene-y-perfumeria',
        component: HigienePerfumeriaComponent,
        data: { titulo: 'Higiene y perfumería' },
      },
      {
        path: 'salud-sexual',
        component: SaludSexualComponent,
        data: { titulo: 'Salud sexual' },
      },
      {
        path: 'suplementos',
        component: SuplementosComponent,
        data: { titulo: 'Suplementos' },
      },
      {
        path: 'material-de-curacion',
        component: MaterialDeCuracionComponent,
        data: { titulo: 'Material de curación' },
      },
      {
        path: 'varios',
        component: VariosComponent,
        data: { titulo: 'Varios' },
      },*/
      {
        path: 'busqueda',
        component: BusquedaComponent,
        data: { titulo: 'Busqueda' },
      },
      // { path: 'localiza', component: LocalizaComponent, data: { titulo: 'Localiza' } },
      {
        path: 'contacto',
        component: ContactanosComponent,
        data: { titulo: 'Contacto' },
      },/*
      {
        path: 'franquicia',
        component: FranquiciaComponent,
        data: { titulo: 'Franquicia' },
      },
      {
        path: 'novedades',
        component: PromocionesComponent,
        data: { titulo: 'Novedades' },
      },
      {
        path: 'buscando',
        component: BuscandoComponent,
        data: { titulo: 'Buscando' },
      },
      {
        path: 'bases-legales',
        component: BasesLegalesComponent,
        data: { titulo: 'Bases legales' },
      },*/
      // { path: 'producto/:id/:name', component: ProductoComponent },
      // { path: 'nota/:id', component: NotaComponent, data: { titulo: 'Medicamentos' } },
      // { path: '**', redirectTo: '', pathMatch: 'full' }
    ],
  },
];
export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes);
