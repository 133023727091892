import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  public userLocation?: [number, number];
  private geolocationEnabled = false;

  get isUserLocationReady(): boolean{
    return !!this.userLocation;
  }

  constructor() {
    //this.getUserLocation();
   }

  public setGeolocationEnabled(enabled: boolean): void {
    this.geolocationEnabled = enabled;
    if (enabled) {
      this.getUserLocation();
    }
  };
  public async getUserLocation(): Promise<[number, number]> {
    if (!this.geolocationEnabled) {
      return Promise.reject('Geolocation not enabled');
    }

     return new Promise ((resolve,reject) => {
      navigator.geolocation.getCurrentPosition(
        ({coords}) => {
          this.userLocation = [ coords.longitude, coords.latitude];
          console.log('User Location:', this.userLocation);
          resolve(this.userLocation);
        },
        (err) => {
          alert('No se pudo obtener la ubicación')
          console.log(err);
          reject();
        }
      )
     });

  }




}
